import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import CTA from "../../../components/cta"
import ServiceAreas from "../../../components/serviceAreas"
import SubServicesAlt from "../../../components/subServicesAlt"
import Education from "../../../components/education"
import HeroSectionAlt from "../../../components/heroSectionAlt"
import Testimonials from "../../../components/testimonials"

function UnclogBathtubDrain() {
  const data = useStaticQuery(graphql`
    query unclogBathtubDrainImages {
      bathtubDrain: file(relativePath: { eq: "bathtub-drain-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Bathtub Drain Repair"
        description={`If your bathtub drain is clogged, we can fix it! We have over 30 years of residential plumbing experience, so we'll unclog your tub drain quickly and easily`}
      />
      <HeroSectionAlt
        h1={`Bathtub Drain Repair`}
        h2={`Have a Master Plumber Fix Your Bathtub Drain`}
        h3={`We're experts in unclogging and repairing bathtub drains. Our cutting-edge, proven methods of clearing your drains eliminate any standing water and everything back to normal.`}
        p1={`Klein Plumbing Services has unclogged countless bathtub drains over three decades of serving the North Houston area, which is why our service is fast, professional, and affordable.`}
        heroImg={data.bathtubDrain.childImageSharp.fluid}
        heroImgAltTag={`Bathtub Drain Repair`}
      />
      <Education
        mainTitle={`Signs Your Bathtub Drain Is Clogged`}
        titleOne={`Slow Draining`}
        descriptionOne={`If your bathtub is slow to drain or has residual standing water, you have a clog.`}
        titleTwo={`Strange Odors`}
        descriptionTwo={`Sewer-like smells coming from your drain are a strong sign that you have a blockage developing.`}
        titleThree={`Unusual Sounds`}
        descriptionThree={`When drains are blocked, water may be forced through small spaces, causing gurgling or bubbling sounds.`}
      />
      <SubServicesAlt
        mainTitle={`Our Bathtub Drain Services`}
        titleOne={`Finding the Clog`}
        descriptionOne={`Our master plumber can find the clog in your bathtub drain before it can cause any damage to your plumbing.`}
        titleTwo={`Breaking Up the Blockage`}
        descriptionTwo={`After we have identified the root of the clog, we use industry-leading equipment and methods to break it up.`}
        titleThree={`Ensuring Quality`}
        descriptionThree={`After removing the blockage, our plumber will verify that your bathtub drains like it should and that everything is working properly.`}
      />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <ServiceAreas
        description={`bathtubs`}
        subDescription={`bathtub clogs and drain repair`}
      />
      <CTA
        title={`Get a Free Quote to Unclog Your Drain Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default UnclogBathtubDrain
